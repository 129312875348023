<template>
  <div>
    <el-row style="margin-bottom: 30px" v-if="user.level == 1">
      <el-col :span="11">
        等级：<el-select
          v-model="need_level"
          placeholder="请选择"
          @change="change"
        >
          <el-option label="小组主管" :value="2"> 小组主管 </el-option>
          <el-option label="线下办单人员" :value="4"> 线下办单人员 </el-option>
          <el-option label="线上销售人员" :value="3"> 线上销售人员 </el-option>
        </el-select>
      </el-col>
    </el-row>
    <div
      style="
        background: #f6f6f6;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: end;
      "
    >
      <el-button size="mini" type="danger" @click="handleDelete()"
        >删除</el-button
      >
    </div>
    <el-table border :data="tableData" style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
            border
            :data="props.row.children"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="name" label="姓名"> </el-table-column>
            <el-table-column prop="tel" label="手机号"> </el-table-column>
            <el-table-column prop="level" label="等级"> </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column prop="project" label="所属项目"> </el-table-column>
      <el-table-column prop="level" label="等级"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      delArr: [],
      need_level: "",
      user: JSON.parse(window.localStorage.getItem("user")),
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      this.tableData = [];
      let response = await this.$request.post("/webShowAllUsers", {
        need_level: this.need_level,
      });
      for (let key in response.data.res) {
        let data = {
          project: key,
          level: "",
          children: [],
        };
        response.data.res[key].map((item, index) => {
          if (item.level == 1) {
            item.level = "项目主管";
          } else if (item.level == 2) {
            item.level = "小组主管";
          } else if (item.level == 3) {
            item.level = "线上销售人员";
          } else if (item.level == 4) {
            item.level = "线下办单人员";
          }
          data.level = item.level;
          data.children.push(item);
        });
        this.tableData.push(data);
      }
    },
    change() {
      this.getData();
    },
    // 删除用户
    handleDelete() {
      let _this = this;
      this.delArr.map((item) => {
        if (item.id == this.user.id) {
          this.$message({
            message: "自己不能删除自己！",
            type: "warning",
          });
        }
      });
      if (this.delArr.indexOf(this.user.id) != -1) {
        this.$message({
          message: "自己不能删除自己！",
          type: "warning",
        });
      } else {
        this.$alert("确定删除改用户吗？", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            if (action == "confirm") {
              _this.del();
            }
          },
        });
      }
    },
    async del() {
      let response = await this.$request.post("/delUser", { id: this.delArr });
      if (response.data.success) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getData();
      } else {
        this.$message({
          message: "删除失败",
          type: "erroe",
        });
      }
    },
    handleSelectionChange(val) {
      this.delArr = [];
      val.map((item) => {
        this.delArr.push(item.id);
      });
    },
    
  },
};
</script>
<style lang='less' scoped></style>